import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import {
  InversalLineChart,
  InversalLineChartDisplayOptions,
  ISerie,
  SerieValue
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import * as Chart from 'chart.js';
import { ChartConfiguration, ChartDataSets } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import { parseKeyItemToArray } from '../../../../../../../../../shared/utils/prime.utils';
import { BaseFuseChartTypeInterface } from '../../base-fuse-chart-type.class';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-inversal-line-chart',
  templateUrl: './inversal-line-chart.component.html',
  providers: [ChangedetectorReference]
})
export class InversalLineChartComponent extends BaseFuseChartTypeInterface<InversalLineChart, InversalLineChartDisplayOptions> implements OnInit, OnDestroy {
  /**
   * Canvas element.
   */
  @ViewChild('canvasItem', {static: true}) canvas: ElementRef;

  /**
   * Canvas context.
   */
  ctx: CanvasRenderingContext2D;

  /**
   * Chart object.
   */
  chart: Chart;

  /**
   * This triggers an event when the component has been succesfully initializated.
   */
  private initializedTrigger: BehaviorSubject<boolean>
      = new BehaviorSubject<boolean>(false);

  private subscription: Subscription;


  /**
   * `BasicRadarChartComponent` class constructor.
   */
  constructor(protected cdReference: ChangedetectorReference) {
    super(cdReference);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.initializedTrigger.next(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Chart configuration initializer method.
   */
  initializeChart(): void {
    const labelSerie: ISerie = this.getDataSeries().Series[this.currentChart.LabelSeriesId];
    const labels: SerieValue[] = parseKeyItemToArray<SerieValue>(labelSerie.Values);

    const dataSeries: ISerie[] = parseKeyItemToArray<ISerie>(this.getDataSeries().Series)
        .filter((v: ISerie) => v['key'] !== this.currentChart.LabelSeriesId);

    this.subscription = this.initializedTrigger
        .pipe(filter(v => v), take(1))
        .subscribe(() => {
          this.chart = new Chart(this.ctx, <ChartConfiguration>{
            type: 'line',
            data: {
              labels: dataSeries.map(l => l.Name),
              datasets: this.getDataset(labels, dataSeries)
            },
            options: this.getChartOptions(),
          });
          this.cdReference.changeDetector.detectChanges();
        });
  }

  /**
   * Chart configuration builder.
   * @param {ChartDataSets[]} data
   */
  private getChartOptions(): object {
    return {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: false,
            reverse: true,
            min: this.displayOptions.YScaleMin,
            max: this.displayOptions.YScaleMax,
          },
          scaleLabel: {
            display: true,
            labelString: this.displayOptions.YAxisLabel || ''
          },
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: this.displayOptions.XAxisLabel || ''
          },
        }]
      }
    };
  }

  /**
   * Returns an array of `ChartDataSets` with the data for the chart.
   *
   * @param {SerieValue[]} labels
   * @param {ISerie[]} series
   * @returns {ChartDataSets[]}
   */
  private getDataset(labels: SerieValue[], series: ISerie[]): ChartDataSets[] {
    const colorSchemeLength: number = this.displayOptions.ColorScheme.length;

    return labels.map((label: SerieValue, index: number) => {
      const data: number[] = series.map((serie: ISerie) => (serie.Values[label.Id].Value));
      const color: string = this.colorConvert(this.displayOptions.ColorScheme[index % colorSchemeLength]);

      return this.buildDataset(label.Value, data, color);
    });
  }

  /**
   * Builds a `ChartDataSets` object.
   *
   * @param {string} title
   * @param {number[]} data
   * @param {string} color
   * @param {string} hoverBorderColor
   * @returns {ChartDataSets}
   */
  private buildDataset(title: string, data: number[], color: string, hoverBorderColor?: string): ChartDataSets {
    return {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderColor: color,
      hoverBorderColor: hoverBorderColor || color,
      data: data,
      label: title,
    };
  }

  /**
   * Covert Hex to Rgb
   * @param color
   * @private
   */
  private colorConvert(color: string): string {
    const r: number = parseInt(color.substring(1, 3), 16);
    const g: number = parseInt(color.substring(3, 5), 16);
    const b: number = parseInt(color.substring(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }
}
