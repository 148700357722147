import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import {
  ICalendarMode,
  IViewsSingleItemOperation,
  ViewConfiguration,
  ViewFieldSingleItemOperations,
  ViewFieldTypeEnum,
  ViewModeCalendar,
  ViewModeCalendarUserConfiguration,
  ViewResultCellSingleItemOperation,
  ViewResultCellType,
  ViewResultRow,
  ViewUserConfiguration
} from 'app/core/models/ETG_SABENTISpro_Application_Core_models';
import { SingleItemOperationEventData2 } from 'app/shared/list_v2/events/singleitemoperation.eventdata';
import { ListComponent2Service } from 'app/shared/list_v2/list.service';
import { asIterableObject, isNullOrUndefined, UtilsTypescript } from 'app/shared/utils/typescript.utils';
import { EventAction, EventColor, MonthViewDay } from 'calendar-utils';
import { Subject } from 'rxjs';
import { DateTimeService } from '../../../../../core/date-time/date-time.service';
import { ViewUtils } from '../../../view.utils';
import { ViewsuserconfigchangedAction } from '../../../viewsuserconfigchanged.eventdata';
import { IViewModeComponent } from '../../i-view-mode.component';
import { ViewModeUtils } from '../../viewmode.utils';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-view-mode-calendar',
  templateUrl: './view-mode-calendar.component.html',
  styleUrls: ['./view-mode-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations
})
export class ViewModeCalendarComponent implements OnInit, OnDestroy, IViewModeComponent {
  private _unsubscribeAll: Subject<any>;

  // -----------------------------------------------------------------------------------------------------
  // @ Sabentis component variables
  // -----------------------------------------------------------------------------------------------------
  /**
   * The View Configuration
   */
  configuration: ViewConfiguration;

  /**
   * The User Configuration
   */
  userConfiguration: ViewUserConfiguration;

  /**
   * The ViewMode Configuration
   */
  viewModeConfiguration: ViewModeCalendar;

  /**
   * The ViewModeUser Configuration
   */
  viewModeUserConfiguration: ViewModeCalendarUserConfiguration;

  /**
   * The available calendar modes in viewModeConfiguration
   */
  calendarViewModes: ICalendarMode[];

  /**
   * The current CalendarMode View
   */
  currentCalendarModeViewValue: ICalendarMode;

  /**
   * The current CalendarMode View
   */
  currentCalendarModeView: string;
  /**
   * The ViewResultData
   */
  private _data: ViewResultRow[];

  // -----------------------------------------------------------------------------------------------------
  // @ Event & Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * Event is clicked
   */
  onEventClicked: EventEmitter<CalendarEvent> = new EventEmitter();

  /**
   * Single Item Operation is clicked
   */
  onSingleItemOperationClicked: EventEmitter<SingleItemOperationEventData2>;

  // -----------------------------------------------------------------------------------------------------
  // @ Inner component attributes
  // -----------------------------------------------------------------------------------------------------

  /**
   * The Calendar Events DT
   */
  events: CalendarEvent[];

  /**
   * Refresh Subject
   */
  refresh: Subject<boolean>;

  /**
   * The current date
   */
  viewDate: Date;

  /*
  * The current language ISO code
  * */
  isoCode: string;

  constructor(
      private _matDialog: MatDialog,
      public listComponentConfiguration: ListComponent2Service,
      private cdr: ChangeDetectorRef,
      private dateTimeService: DateTimeService,
      private translator: TranslatorService,
  ) {
    this._unsubscribeAll = new Subject();
    this.refresh = new Subject<boolean>()
    this.listComponentConfiguration.userConfigurationChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(o => {
          this.initialize();
          if (o.refreshAction === ViewsuserconfigchangedAction.Refresh) {
            if (!isNullOrUndefined(this.listComponentConfiguration.data) && !isNullOrUndefined(this.listComponentConfiguration.data.Results)) {
              this._data = this.listComponentConfiguration.data.Results;
            } else {
              this._data = [];
            }
            this.initializeEvents();
          }
          this.refresh.next(false);
        });

    this.refresh
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((getData: boolean) => {
          this.detectChanges();
        });
  }

  get data(): ViewResultRow[] {
    return this._data;
  }

  @Input()
  set data(value: ViewResultRow[]) {
    this._data = value;
    this.initializeEvents();
    this.refresh.next(true);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this.detectChanges();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  /**
   * Initialize the component
   */
  initialize(): void {
    this.configuration = this.listComponentConfiguration.getConfiguration();
    this.userConfiguration = this.listComponentConfiguration.getUserConfiguration()
    this.viewModeConfiguration = ViewModeUtils.GetCurrentViewModeFromService(this.listComponentConfiguration) as ViewModeCalendar;
    this.viewModeUserConfiguration = ViewModeUtils.GetCurrentViewModeUserConfigurationColumnsBased(this.userConfiguration) as ViewModeCalendarUserConfiguration;
    this.calendarViewModes = asIterableObject(this.viewModeConfiguration.AvailableCalendarModes);
    this.viewDate = this.dateTimeService.ParseDate(this.viewModeUserConfiguration.CurrentDate);
    this.isoCode = this.translator.getAngularLang();
    // Adaptaciones al componente

    if (!isNullOrUndefined(this.viewModeUserConfiguration.CurrentCalendarMode)) {
      this.currentCalendarModeView = this.viewModeUserConfiguration.CurrentCalendarMode.toLocaleLowerCase();
    }

    this.onSingleItemOperationClicked = this.listComponentConfiguration.onSingleItemOperation;

    if (!isNullOrUndefined(this.viewModeUserConfiguration.CurrentCalendarMode)) {
      this.currentCalendarModeViewValue = this.viewModeConfiguration.AvailableCalendarModes[this.viewModeUserConfiguration.CurrentCalendarMode];
    }

    if (this._data) {
      this.detectChanges();
    }
  }

  /**
   * Day clicked
   *
   * @param {MonthViewDay} day
   */
  dayClicked(data: { day: MonthViewDay<any>; }): void {
    this.updateViewDate(data.day.date);
    this.refresh.next(true);
    this.detectChanges();
  }

  /**
   * Initialize the Data
   */
  initializeEvents(): void {
    if (this._data !== null && this._data !== undefined && this._data.length > 0) {
      this.events = this._data.map((item: ViewResultRow) => {
        return this.creteEventFromRow(item);
      });
      console.debug('Events: ' + this.events)
    } else {
      this.resetData();
    }
  }

  /**
   * Change de date
   * @param date
   */
  changeDate(date: Date): void {
    this.updateViewDate(date);
    this.cdr.detectChanges();
    this.updateUserConfiguration();
  }

  /**
   *
   * @param mode
   */
  changeCalendarMode(mode: ICalendarMode): void {
    this.viewModeUserConfiguration.CurrentCalendarMode = mode.Id;
    this.updateUserConfiguration();
  }

  /**
   * Detect changes
   */
  detectChanges(): void {
    this.cdr.detectChanges();
  }

  /**
   * Event clicked
   * @param event
   */
  eventClicked(data: { event: CalendarEvent<any> }): void {
    this.onEventClicked.emit(data.event);
    this.detectChanges();
  }

  beforeMonthViewRender(data: CalendarMonthViewBeforeRenderEvent): void {

  }


  /**
   * Event times changed
   * Event dropped or resized
   *
   * @param {CalendarEvent} event
   * @param {Date} newStart
   * @param {Date} newEnd
   */
  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    this.refresh.next(true);
  }

  /**
   * From a ViewResultEvent creates a CalendarEvent.
   * @param data
   */
  private creteEventFromRow(data: ViewResultRow): CalendarEvent {
    const startDateRawValue: string = data.Columns[this.viewModeConfiguration.EventDefinition.InitialDateField].RawValue;

    const title: string = this.viewModeConfiguration.EventDefinition.TitleField
        .map(value => {
          return ViewUtils.GetFieldColumnValue(data, value);
        }).join('<br>');
    const result: CalendarEvent =
        {
          start: this.dateTimeService.ParseDate(startDateRawValue),
          title: title,
        };

    if (this.viewModeConfiguration.EventDefinition.FinalDateField) {
      const endDateRawValue: string
          = data.Columns[this.viewModeConfiguration.EventDefinition.FinalDateField].RawValue;
      if (endDateRawValue) {
        result.end = this.dateTimeService.ParseDate(endDateRawValue);
      }
    }
    if (this.viewModeConfiguration.EventDefinition.AllDayField) {
      const value: boolean
          = data.Columns[this.viewModeConfiguration.EventDefinition.AllDayField].RawValue as boolean;
      if (value) {
        result.allDay = value;
      }
    }

    if (this.viewModeConfiguration.EventDefinition.BackgroundColorField) {
      if (!result.color) {
        result.color = {} as EventColor;
      }
      const value: string
          = data.Columns[this.viewModeConfiguration.EventDefinition.BackgroundColorField].RawValue as string;
      if (value) {
        result.color.secondary = '#' + value;
      }
    }
    if (this.viewModeConfiguration.EventDefinition.BorderColorField) {
      if (!result.color) {
        result.color = {} as EventColor;
      }
      const value: string
          = data.Columns[this.viewModeConfiguration.EventDefinition.BorderColorField].RawValue as string;
      if (value) {
        result.color.primary = '#' + value;
      }
    }
    result.meta = {row: data};

    const sioCell: ViewResultCellSingleItemOperation = asIterableObject(data.Columns).find((i) => i.Type === ViewResultCellType.SingleItemOperation) as ViewResultCellSingleItemOperation;
    if (sioCell !== null && sioCell !== undefined) {
      const sioField: ViewFieldSingleItemOperations = asIterableObject(this.configuration.Fields).find((i) => i.Type === ViewFieldTypeEnum.SingleItemOperations) as ViewFieldSingleItemOperations;
      const empyCellOperations: boolean = UtilsTypescript.isNullOrUndefined(sioCell.SingleItemOperations);
      const operationsDictionary: {
        [key: string]: IViewsSingleItemOperation
      } = empyCellOperations ? sioField.SingleItemOperations : sioCell.SingleItemOperations;
      const operations: IViewsSingleItemOperation[] = asIterableObject(operationsDictionary);
      result.actions = operations.map(x => {
        return {
          id: x.Id,
          label: x.Title,
          onClick: ({event}: { event: CalendarEvent }): void => {
            this.onActionClicked(event, x);
          }
        } as EventAction;
      });
    }
    return result;
  }

  /**
   * onClick event CalendarEvent
   * @param event
   * @param action
   */
  private onActionClicked(event: CalendarEvent, action: IViewsSingleItemOperation): void {
    const sio: SingleItemOperationEventData2 = new SingleItemOperationEventData2();
    sio.row = event.meta.row;
    sio.action = action.Id;
    sio.operation = action;
    this.onSingleItemOperationClicked.next(sio);
  }

  /**
   * Update the UserConfiguration with the current Calendar configuration UI in order to get new Data
   */
  private updateUserConfiguration(): void {
    this.viewModeUserConfiguration.CurrentDate = this.viewDate;
    this.userConfiguration.AvailableUserViewModes[this.viewModeUserConfiguration.Id] = this.viewModeUserConfiguration;
    this.userConfiguration.CurrentViewMode = this.viewModeUserConfiguration;
    this.listComponentConfiguration.setUserConfiguration(this.userConfiguration);
  }

  private resetData(): void {
    this._data = [];
    this.events = [];
  }

  private updateViewDate(date: Date): void {
    /*const auxDate: Date = new Date(this.viewDate);
    auxDate.setUTCDate(date.getDate());
    auxDate.setUTCMonth(date.getMonth());
    auxDate.setUTCFullYear(date.getFullYear());*/
    this.viewDate = date;
  }
}


