/**
 * Authentication service.
 */
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { Injectable, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { filter, map, pairwise, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NEVER, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AppConfigurationService } from '../../app.configuration.service';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../services/ETG_SABENTISpro_Application_Core_session.service';
import { finalizeWithReason } from '../../utils/rxJsFinalizeWithReason';
import { BootstrapService } from '../../app-bootstrap.service';
import { BrowserTabService } from '../browser-tab/browser-tab.service';

/**
 * EL funcionamiento de este servicio es muy sencillo, realmente más que AUTH, es un servicio
 * de gestión del estado de la aplicación (sesión).
 *
 * Permite suscribirse a cambios en el valor de cualquier atributo del estado de la sesión
 * en el servidor (el estado de la sesión en realidad es interno, y estamos hablando
 * del estado que se expone a través del método getDisplayableinfo).
 */
@Injectable({
  providedIn: 'root'
})
export class SessionstateService extends DestroyableObjectTrait implements OnDestroy {

  /**
   * El hash que identifica el "estado" de la sesión. El interceptor
   * SessionStateRefreshInterceptor se encarga de enviar esto al servidor
   * en las cabeceras para que éste nos informe - si los hay - de cambios
   * de estado den la sesión retransmitiendo un nuevo estado
   * en las cabeceras del response.
   */
  private sessionHash: string = null;

  /**
   * Evento interno que se emite con el estado de sesión
   * que tengamos.
   *
   * @private
   */
  private sessionDataChanged: Subject<object>;

  /**
   * del estado de la sesión.
   *
   * @private
   */
  private sessionAttributeChangedEmitters: { [id: string]: Subject<any> } = {};

  /**
   * Emite un evento con los contenidos de la sesión
   */
  public get $sessionData(): Observable<object> {
    return this.sessionDataChanged;
  }

  /**
   * Hash actual de la sesión.
   *
   * @constructor
   */
  public get CurrentSessionHash(): string {
    return this.sessionHash;
  }

  /**
   * Permite suscribirse a cambios en atributos concretos del objeto
   * de sesión.
   *
   * @param <T>
   *  En realidad el observable emite un objeto indeterminado de sesión (ANY)
   *  este T es simplemente una ayuda para tipar el valor emitido en los listeners
   *  ya que los listeners suelen esperar un DTO concreto. Pero la sesión como
   *  tal es compartida y dinámica, y no hay un DTO específico que cubra todo
   *  lo que contiene.
   * @param properties
   *   Nombre de la propiedad a cuyos cambios de valor queremos suscribirnos.
   */
  public $sessionDataPropertyChanged<T>(properties: string[]): Observable<T> {
    return this.ensurePropertyChangeEmitterExistsInternal<T>(properties);
  }

  /**
   * Ctor
   * @param {Router} router
   * @param {HttpClient} http
   * @param {AppConfigurationService} appConfigurationService
   */
  constructor(
      private router: Router,
      private http: HttpClient,
      private appConfigurationService: AppConfigurationService,
      private sessionService: SessionService,
      private bootstrapService: BootstrapService,
      private browserTabService: BrowserTabService,
      @Optional() @SkipSelf() parentModule?: SessionstateService,
  ) {

    super();

    if (parentModule) {
      throw new Error(
          'SessionstateService is already loaded. Import it in the AppModule only.');
    }

    this.sessionDataChanged = new ReplaySubject<Object>();

    const storedSessionInfo: { info: object, sessionHash: string } = this.persistedSessionInfo;
    this.sessionHash = storedSessionInfo?.sessionHash;

    this.bootstrapService.bootDataReady$().subscribe(() => {
      console.log('Setting theme: Session boot data ready')
      this.sessionDataChanged.next(storedSessionInfo?.info ?? {});
    });

    // Esto es para garantizar que - al activar/desactivar pestañas
    // el estado de autenticación es coherente con lo que
    // hay en las otras pestañas.
    this.browserTabService.currentTabIsVisibleObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((x: boolean) => x === true),
        )
        .subscribe(x => {
          // El que tenemos en memoria es diferente al persistido, lo que indica
          // que en otra pestaña ha habido un cambio de estado de la sesión,
          // y debemos refrescar el estado local de la aplicación. De todos modos
          // el método SessionStateChanged ya tiene esta comprobación dentro, pero
          // por si un caso la hacemos también aquí.
          if (this.sessionHash !== this.persistedSessionInfo?.sessionHash) {
            // Hay un caso concreto en el que, al cerrar sesión, se hace un localstorage.clear() lo que borra
            // los hashes almacenados.
            if (this.persistedSessionInfo === null) {
              this.SessionStateChanged(this.sessionHash, true)
                  .subscribe();
            } else {
              this.SessionStateChanged(this.persistedSessionInfo.sessionHash)
                  .subscribe();
            }
          }
        });
  }

  /**
   * Persistir la información de sesión (tanto el hash actual como los contenidos)
   * para que cuando recuperemos la aplicación, lo hagamos donde estaba.
   */
  public get persistedSessionInfo(): { info: object, sessionHash: string } {
    return JSON.parse(localStorage.getItem('sessionInfo'));
  }

  /**
   * Persistir la información de sesión (tanto el hash actual como los contenidos)
   * para que cuando recuperemos la aplicación, lo hagamos donde estaba.
   */
  public set persistedSessionInfo(info: { info: object, sessionHash: string }) {
    localStorage.setItem('sessionInfo', JSON.stringify(info));
  }

  /**
   * Para que el interceptor avise de que han habido cambios en el estado de la sesión.
   * @param sessionHash
   * @constructor
   */
  public SessionStateChanged(sessionHash: string, force: boolean = false): Observable<any> {

    // Si el hash es el mismo que el que tenemos, no hacer nada.
    if (sessionHash === this.sessionHash && !force) {
      return of(true);
    }

    // Guardar el hash original para restaurarlo en caso de fallo
    // de obtención de los datos de seseión
    const sessionHashBeforeRequest: string = this.sessionHash;
    this.sessionHash = sessionHash;

    console.debug('Updating session hash from ' + sessionHashBeforeRequest + ' to ' + sessionHash);

    return this
        .sessionService
        .getDisplayableinfo()
        .pipe(
            tap((result) => {
              this.persistedSessionInfo = {sessionHash: sessionHash, info: result.result};
              this.sessionDataChanged.next(result.result);
            }),
            finalizeWithReason(() => {
              // TODO: Este control de nulo está aquí para el cierre de sesión,
              // se hace un localstorage.clear() después de haber cerrado la sesión
              // así que la info persistida pasa a ser nula
              if (this.persistedSessionInfo === null) {
                return;
              }
              // Si no hemos podido actualizar la info de sesión, restaurar el hash viejo
              // (que es inválido) para que otro request actualice.
              if (this.persistedSessionInfo.sessionHash !== sessionHash) {
                console.debug('Unable to update session hash, restore to ' + sessionHashBeforeRequest);
                this.sessionHash = sessionHashBeforeRequest;
              }
            }),
        );
  }

  private ensurePropertyChangeEmitterExistsInternal<T>(properties: string[]): Subject<T> {
    const observableKey: string = properties.join(':');
    if (!Object.keys(this.sessionAttributeChangedEmitters).find((i) => i === observableKey)) {
      const propertySubject: ReplaySubject<any> = new ReplaySubject<any>(1);
      this.sessionAttributeChangedEmitters[observableKey] = propertySubject;
      this.sessionDataChanged
          .pipe(
              startWith(null),
              pairwise(),
              switchMap(([previousData, newData]) => {
                if (newData === null) {
                  console.error('El valor nulo NO debe usarse para representar una sesión vacía. Utiliza {}.')
                }
                for (const prop of properties) {
                  const previousValue: any = JSON.stringify(previousData ? previousData[prop] : null);
                  const newValue: any = JSON.stringify(newData ? newData[prop] : null);
                  // Reservamos el valor "NULL" como valor de estado incial
                  if (previousValue !== newValue || previousValue === JSON.stringify(null)) {
                    return of(newData);
                  }
                }
                return NEVER;
              }),
              map((newValue) => {
                propertySubject.next(newValue);
              })
          )
          .subscribe();
    }
    return this.sessionAttributeChangedEmitters[observableKey];
  }
}
